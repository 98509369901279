<template>
  <div>
    <v-dialog
      v-model="show"
      :max-width="loading ? '40vh' : '90vh'"
      transition="dialog-top-transition"
      @click:outside="deleteContact(false)"
    >
      <!-- @click:outside="closeModalUpdateContact()"⁄ -->
      <v-card v-if="loading" height="200">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                Verificando algunos datos ...
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card
        v-else-if="!loading && leadsOfContact && leadsOfContact.length > 0"
      >
        <v-card-title class="headline pa-5 info--text">
          No puedes eliminar a este Asesor.
        </v-card-title>
        <v-card-text class="py-4 px-10">
          <div class="py-4">
            Debido a que
            <span
              v-if="contactToDelete && contactToDelete.name"
              class="pa-2 blue--text text--darken-4"
              >{{ contactToDelete.name }}
            </span>
            <span v-else class="pa-2 blue--text text--darken-4"
              >(No se encontro un nombre</span
            >
            tiene leads asignados, la acción de eliminar asesor no es posible
            realizarse.
          </div>
          <div>
            Dirígite a tu tabla de leads, filtra los leads de este asesor y
            reasignalos.
          </div>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="indigo darken-1" text @click="deleteContact(false)">
            Cerrar este dialago
          </v-btn>
          <v-btn dark elevation="0" color="primary" @click="goToTableLeads()">
            Ir a tabla de Leads
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else rounded="lg">
        <v-card-title class="headline pa-5 red--text">
          ¿Eliminar Asesor?
        </v-card-title>
        <v-card-text class="py-4 px-10">
          <div class="py-4">
            ¿Seguro que deseas eliminar a
            <span
              v-if="contactToDelete && contactToDelete.name"
              class="pa-2 delete-name"
              >{{ contactToDelete.name }}
            </span>
            <span v-else class="pa-2 delete-name"
              >No se encontro un nombre</span
            >
            de tu lista de asesores?
          </div>
          <div>
            Esta acción no se puede deshacer.
          </div>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="indigo darken-1" text @click="deleteContact(false)">
            Cancelar
          </v-btn>
          <v-btn
            dark
            elevation="0"
            color="red darken-1"
            @click="deleteContact(true)"
          >
            Si, Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      delete: false,
      contactToDelete: null,
      loading: true,
      leadsOfContact: null
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.delete);
        }
      }
    },
    showDiscardedAndActiveLeads: {
      get() {
        return this.$store.state.leads.filterShowDiscardedAndActive;
      },
      set(newValue) {
        // al activar el filtro descartados, deshabilitar filtro requieren atención
        if (newValue) this.showDelayedLeads = false;
        this.$store.commit("leads/setFilterShowDiscardedAndActive", newValue);
      }
    },
    filterBrokers: {
      get() {
        return this.$store.state.leads.filterBrokers;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterBrokers", newValue);
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLeadsBydContactId: "leads/fetchLeadsBydContactId"
    }),
    goToTableLeads() {
      this.showDiscardedAndActiveLeads = true;
      this.filterBrokers = this.contactToDelete._id;
      this.$router.push({ name: "AdminLeadsView" });
    },
    closeDeleteContactModal(val) {
      this.show = val;
    },
    deleteContact(val) {
      this.delete = val;
      this.closeDeleteContactModal(false);
    },
    setContactToDelete(item) {
      this.contactToDelete = item;
    },
    fechtLeadsById(item) {
      const self = this;
      self.loading = true;
      if (item && item._id) {
        self.fetchLeadsBydContactId(item).then(res => {
          self.leadsOfContact = res.Lead ? res.Lead : [];
          self.loading = false;
        });
      } else {
        self.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.delete-name {
  font-weight: 400;
  font-size: 34 px;
  color: red;
}
</style>
